import React, { useEffect } from "react";
import "./Header.css";

const Header = () => {
  const headerRef = React.createRef();

  const handleHeaderScroll = () => {
    let opacity = 1.5 - window.pageYOffset / 100;
    if (opacity >= 0) {
      headerRef.current.style.opacity = opacity;
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleHeaderScroll);
    return () => window.removeEventListener("scroll", handleHeaderScroll);
  }, []);

  return (
    <div id="header">
      <div id="titleSection">
        <h1 id="firstName" ref={headerRef}>
          HUGO
        </h1>
        <h1 id="lastName">BERGQVIST</h1>
      </div>
    </div>
  );
};

export default Header;

/**          <img
            id="profileImage"
            src={require("../../../images/IMG_6912.jpg")}
          ></img> */
