import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import About from "./components/container/About";
import "./components/container/containerStyle.css";
import Home from "./components/container/Home";
import Skills from "./components/container/Skills";
import Header from "./components/presentational/Header/Header";
import Navbar from "./components/presentational/Navigation/Navbar";

function App() {
  return (
    <div className="App">
      <div className="note">
        <p style={{ fontSize: "8pt", color: "grey", marginBottom: "8px" }}>
          May 15, 2023
        </p>
        <p style={{ marginBottom: "8px" }}>
          Hello{" "}
          <span role="img" aria-label="waving-hand">
            👋
          </span>
        </p>

        <p style={{ marginBottom: "8px" }}>
          This site is not actively updated, I'll get to it at some point! My{" "}
          <a
            href="https://www.linkedin.com/in/hugo-the-developer/"
            rel="noopener noreferrer"
            target="_blank"
          >
            linkedin
          </a>{" "}
          is the most up to date.
        </p>
        <p>All the best, Hugo</p>
      </div>
      <Router>
        <Header />
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/skills" component={Skills} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
