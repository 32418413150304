import React from "react";
import "./ProjectBrick.css";
import ImageLoader from "../../ImageLoader/ImageLoader";

const ProjectBrick = ({
  color,
  cardId,
  image,
  image2,
  startDate,
  endDate,
  title,
  job,
  description,
  codeLogos,
  link,
  inputRef,
}) => {
  let laptopImgStyle = {
    width: "65%",
    minHeight: "200px",
    alignSelf: "center",
    justifySelf: "center",
  };

  let phoneImgStyle = {
    width: "35%",
    minHeight: "200px",
    alignSelf: "center",
    justifySelf: "center",
  };
  return (
    <div className="projectBrick shadow" id={`${cardId}-brick`} ref={inputRef}>
      <div className="projectBrickImgContainer">
        <ImageLoader
          source={image}
          imageClass={image2 ? "brickImagePhone" : "brickImageLaptop"}
          imageAlt="ProjectBrickImg"
          divStyle={image2 ? phoneImgStyle : laptopImgStyle}
        />
        {image2 && (
          <ImageLoader
            source={image2}
            imageClass={"brickImagePhone"}
            imageAlt="ProjectBrickImg"
            divStyle={phoneImgStyle}
          />
        )}
      </div>
      <div className="brickDetails">
        <h5 className="brickDate" style={{ color }}>
          {startDate}-{endDate}
        </h5>
        <h3 className="brickTitle">{title}</h3>
        <h4 className="brickJob" style={{ color }}>
          {job}
        </h4>
        <p className="brickDescription">{description}</p>
      </div>
      {codeLogos && (
        <div className="brickLogoContainer staticShadow">
          {codeLogos.map((logo, index) => {
            return (
              <img
                className="brickLogo"
                key={`${cardId}logo-${index}`}
                src={logo}
                alt="codeLogo"
              />
            );
          })}
        </div>
      )}
      <a
        href={link}
        className="brickButtonLink"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button
          className="brickButton staticShadow"
          style={{ backgroundColor: color }}
        >
          Check it out!
        </button>
      </a>
    </div>
  );
};

export default ProjectBrick;
