import React, { useEffect, useState } from "react";
import "./bubbleStyles.css";
import BubbleDetails from "./BubbleDetails";
import Bubble from "./Bubble";

var bubbleCounter = 1;

const GravityBubbleContainer = ({ bubbles }) => {
  const [bubbleRow2Up, setBubbleRow2Up] = useState([]);
  const [bubbleRow1Up, setBubbleRow1Up] = useState([]);
  const [bubbleRowMid, setBubbleRowMid] = useState([]);
  const [bubbleRow1Down, setBubbleRow1Down] = useState([]);
  const [bubbleRow2Down, setBubbleRow2Down] = useState([]);
  const [hoverBubble, setHoverBubble] = useState(false);
  const [expandBubble, setExpandBubble] = useState(false);
  const [currentBubbleInfo, setCurrentBubbleInfo] = useState({});
  const [screenMode, setScreenMode] = useState();

  let maxHoverSize = 125;
  let maxBubbleSize = 40;

  const handleResize = () => {
    if (window.innerWidth < 400) {
      setScreenMode("thin");
      maxHoverSize = 100;
      maxBubbleSize = 30;
    } else {
      setScreenMode("wide");
    }
    generateRows();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const generateRows = () => {
    let tempMid = [];
    let temp1Up = [];
    let temp2Up = [];
    let temp1Down = [];
    let temp2Down = [];

    let addTo = "2up";

    bubbleCounter = 1;

    bubbles.forEach((bubble) => {
      let newBubble = (
        <Bubble
          key={bubbleCounter}
          title={bubble.title}
          description={bubble.description}
          image={bubble.image}
          skillLevel={bubble.skillLevel}
          bubbleNumber={bubbleCounter}
          setHoverBubble={setHoverBubble}
          hoverBubble={hoverBubble}
          setExpandBubble={setExpandBubble}
          setCurrentBubbleInfo={setCurrentBubbleInfo}
        ></Bubble>
      );

      if (bubbleCounter < 3) {
        tempMid.push(newBubble);
      } else if (bubbleCounter < 4) {
        temp1Up.push(newBubble);
      } else if (bubbleCounter < 5) {
        temp1Down.push(newBubble);
      } else if (bubbleCounter < 6) {
        tempMid.push(newBubble);
      } else if (bubbleCounter < 7) {
        temp1Up.push(newBubble);
      } else if (bubbleCounter < 8) {
        temp1Down.push(newBubble);
      } else if (addTo === "2up") {
        temp2Up.push(newBubble);
        addTo = "2down";
      } else if (addTo === "2down") {
        temp2Down.push(newBubble);
        addTo = "mid";
      } else if (addTo === "1up") {
        temp1Up.push(newBubble);
        addTo = "1down";
      } else if (addTo === "1down") {
        temp1Down.push(newBubble);
        addTo = "2up";
      } else if (addTo === "mid") {
        tempMid.push(newBubble);
        addTo = "1up";
      }
      bubbleCounter += 1;
    });
    setBubbleRowMid(tempMid);
    setBubbleRow1Up(temp1Up);
    setBubbleRow2Up(temp2Up);
    setBubbleRow1Down(temp1Down);
    setBubbleRow2Down(temp2Down);
  };

  useEffect(() => {
    let tempBubbles = [
      ...bubbleRowMid,
      ...bubbleRow1Up,
      ...bubbleRow1Down,
      ...bubbleRow2Down,
      ...bubbleRow2Up,
    ];

    if (hoverBubble && !expandBubble) {
      let hoverBub = document.getElementById(`bubble-${hoverBubble}`);
      hoverBub.style.width = `${maxHoverSize}px`;
      hoverBub.style.height = `${maxHoverSize}px`;

      let hoverPositions = hoverBub.getBoundingClientRect();

      tempBubbles.forEach((bubble) => {
        let element = document.getElementById(
          `bubble-${bubble.props.bubbleNumber}`
        );
        if (element !== hoverBub) {
          let xPos = element.getBoundingClientRect().x;
          let yPos = element.getBoundingClientRect().y;
          let xDiff = Math.abs(hoverPositions.x - xPos);
          let yDiff = Math.abs(hoverPositions.y - yPos);
          let avg = Math.round(
            Math.sqrt(Math.pow(xDiff, 2) + Math.pow(yDiff, 2))
          );
          let size = 125 - avg / 3;
          if (size < 0) {
            size = 10;
          }
          element.style.width = size + "px";
          element.style.height = size + "px";
        }
      });
    } else if (!expandBubble) {
      tempBubbles.forEach((bubble) => {
        let element = document.getElementById(
          `bubble-${bubble.props.bubbleNumber}`
        );
        element.style.width = `${maxBubbleSize}px`;
        element.style.height = `${maxBubbleSize}px`;
      });
    }
  }, [hoverBubble]);

  useEffect(() => {
    generateRows();
  }, [bubbles]);

  return (
    <div id="bubbleContainer">
      {expandBubble ? (
        <BubbleDetails
          screenMode={screenMode}
          info={currentBubbleInfo}
          setCurrentBubbleInfo={setCurrentBubbleInfo}
          setExpandBubble={setExpandBubble}
        />
      ) : (
        <React.Fragment>
          <div id="2up" className="bubbleRow">
            {bubbleRow2Up}
          </div>
          <div id="1up" className="bubbleRow">
            {bubbleRow1Up}
          </div>
          <div id="mid" className="bubbleRow">
            {bubbleRowMid}
          </div>
          <div id="1down" className="bubbleRow">
            {bubbleRow1Down}
          </div>
          <div id="2down" className="bubbleRow">
            {bubbleRow2Down}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default GravityBubbleContainer;
