import React from "react";
import "./AboutContainer.css";
import ImageLoader from "../ImageLoader/ImageLoader";

const AboutContainer = () => {
  let iconLoaderStyle = { height: "40px", width: "40px", borderRadius: "50%" };
  return (
    <div id="aboutContainer">
      <div id="profileWrapper" className="constantShadow">
        <ImageLoader
          source={require("../../../images/profile.png")}
          imageId="profileImage"
        />
      </div>

      <h3 className="aboutTitle">Interests</h3>
      <div id="interestsRow">
        <div className="interest">
          <a
            href="https://thenounproject.com/search/?q=golf&i=51047"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImageLoader
              imageClass="interestIcon"
              source={require("../../../images/aboutIcons/golfIcon.svg")}
              imageAlt="interestIcon"
              divStyle={iconLoaderStyle}
            />
          </a>
          <p className="interestText">Golf</p>
        </div>

        <div className="interest">
          <a
            href="https://thenounproject.com/search/?q=laptop&i=3342372"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImageLoader
              imageClass="interestIcon"
              source={require("../../../images/aboutIcons/laptopIcon.svg")}
              imageAlt="interestIcon"
              divStyle={iconLoaderStyle}
            />
          </a>
          <p className="interestText">Code</p>
        </div>
        <div className="interest">
          <a
            href="https://thenounproject.com/search/?q=nature&i=1998963"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImageLoader
              imageClass="interestIcon"
              source={require("../../../images/aboutIcons/natureIcon.svg")}
              imageAlt="interestIcon"
              divStyle={iconLoaderStyle}
            />
          </a>
          <p className="interestText">Nature</p>
        </div>
        <div className="interest">
          <ImageLoader
            imageClass="interestIcon"
            source={require("../../../images/aboutIcons/creativeIcon.svg")}
            imageAlt="interestIcon"
            divStyle={iconLoaderStyle}
          />

          <p className="interestText">Creating</p>
        </div>
      </div>
      <h3 className="aboutTitle">A little about me</h3>
      <p id="aboutText">
        Im happy you found your way to my portfolio. This is a project I created
        using react but without any external dependencies except router, just
        for the sport of it. I’m currently reading my master in Interactive
        Media Technology specializing in visual media at KTH in Stockholm,
        Sweden. Apart from studying I’m also working for KTH on a research
        project called ’Green Cobra’ which you can read more about under
        Experiences. When it comes to code I am more front end than back end, I
        love the instant visual feedback and creating interfaces that makes
        intuitive sense for the user. That said, back end still intrigues me and
        I find aspects such as database efficiency, stability and flexibility
        challenging but very cool. React JS has been my go to framework for a
        while now and I really enjoy using it but I am getting increasingly
        eager to try out new languages and frameworks, so don’t hesitate to
        reach out regardless of stack!
      </p>
      <h3 className="aboutTitle">Contact</h3>

      <div id="aboutSocialMediaContainer">
        <a
          className="contactLink"
          href="https://se.linkedin.com/in/hugo-bergqvist-70b338141"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="contactCell shadow">
            <p className="contactText">Linked In</p>
            <ImageLoader
              imageClass="interestIcon"
              source={require("../../../images/linkedInIcon.svg")}
              imageAlt="interestIcon"
              divStyle={iconLoaderStyle}
            />
          </div>
        </a>
        <a
          className="contactLink shadow"
          href="https://github.com/hugobergqvist"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="contactCell">
            <p className="contactText"> Github</p>
            <ImageLoader
              imageClass="interestIcon"
              source={require("../../../images/githubLogo.svg")}
              imageAlt="interestIcon"
              divStyle={iconLoaderStyle}
            />
          </div>
        </a>
      </div>
    </div>
  );
};

export default AboutContainer;
