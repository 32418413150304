import React, { useState } from "react";
import ProjectBrick from "./ProjectBrick/ProjectBrick";
import "./Projects.css";
import apolloLogo from "../../../images/codeLogos/ApolloLogo.png";
import firebaseLogo from "../../../images/codeLogos/firebaseLogo.svg";
import graphQLLogo from "../../../images/codeLogos/graphQLLogo.svg";
import reactLogo from "../../../images/codeLogos/reactLogo.svg";
import reactNativeLogo from "../../../images/codeLogos/reactNativeLogo.svg";
import reduxLogo from "../../../images/codeLogos/ReduxLogo.svg";
import pythonLogo from "../../../images/codeLogos/pythonLogo.svg";
import JSLogo from "../../../images/codeLogos/JSLogo.svg";
import HTMLLogo from "../../../images/codeLogos/HTMLLogo.svg";
import CSSLogo from "../../../images/codeLogos/CSSLogo.svg";
import d3Logo from "../../../images/codeLogos/d3Logo.png";
import unityLogo from "../../../images/codeLogos/UnityLogo.svg";
import cSharpLogo from "../../../images/codeLogos/cSharpLogo.svg";
import blenderLogo from "../../../images/designLogos/blenderLogo.svg";
import asepriteLogo from "../../../images/designLogos/asepriteLogo.svg";
import audacityLogo from "../../../images/designLogos/audacityLogo.svg";

import { AppearingContainer } from "react-appear-on-scroll";
import "react-appear-on-scroll/dist/index.css";
const Projects = () => {
  const [expanded, setExpanded] = useState("none");

  const handleExpand = (project) => {
    if (project !== expanded) {
      setExpanded(project);
    } else {
      setExpanded("none");
    }
  };

  return (
    <div id="projects">
      <div className="projectContainer" id="codeProjects">
        <h1 className="projectContainerTitle">Developer </h1>
        <AppearingContainer
          wrapperStyling={{ padding: "10px" }}
          parentStyling={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animationType="scale"
          transitionType="smooth"
        >
          <ProjectBrick
            cardId="projectLitter"
            title="Project Litter"
            image={require("../../../images/projectImages/projectLitter2.png")}
            image2={require("../../../images/projectImages/projectLitter1.png")}
            job="Developer"
            startDate="Okt 2020"
            endDate="Jan 2021"
            description="For this project we created a community app to incentivize litter picking. Every time you pick litter, you press a bluetooth flic-button and your position gets logged, stored and displayed in the app. Swtich from community to invidual mode to see who picks the most!"
            link="https://github.com/davidmagnussson/project-litter"
            color="#7A6FD2"
            codeLogos={[reactNativeLogo, firebaseLogo]}
          />
        </AppearingContainer>
        <AppearingContainer
          wrapperStyling={{ padding: "10px" }}
          parentStyling={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animationType="scale"
          transitionType="smooth"
          fading
        >
          <ProjectBrick
            cardId="tangibleTactics"
            title="Tangible Tactics"
            image={require("../../../images/projectImages/TangibleTactics.png")}
            job="Game Developer"
            startDate="Sep 2020"
            endDate="Dec 2020"
            description="
          A fun project where we created physical tangible objects with which the user could play our tower defense styled game on an ipad. A fantastic opportunity to get more familiarized with Unity and coding in C#."
            link="https://tangibletactics.web.app/"
            color="#83442D"
            codeLogos={[unityLogo, cSharpLogo, blenderLogo]}
          />
        </AppearingContainer>
        <AppearingContainer
          wrapperStyling={{ padding: "10px" }}
          parentStyling={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animationType="scale"
          transitionType="smooth"
          fading
        >
          <ProjectBrick
            cardId="feedIt"
            title="FeedIt"
            image={require("../../../images/projectImages/FeedItLaptop.png")}
            job="Game Art and Development"
            startDate="Nov 2020"
            endDate="Dev 2020"
            description="A retro styled endless runner game with a twist, Cooking! My role in this project was mostly game art and voice SFX, but I was responsible for integrating the art into the game as well. Feel free to try the demo on the website!"
            link="https://feedit.web.app/"
            color="#57B5AA"
            codeLogos={[unityLogo, cSharpLogo, asepriteLogo, audacityLogo]}
          />
        </AppearingContainer>
        <AppearingContainer
          wrapperStyling={{ padding: "10px" }}
          parentStyling={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animationType="scale"
          transitionType="smooth"
          fading
        >
          <ProjectBrick
            cardId={"greenCobra"}
            title="GreenCobra"
            image={require("../../../images/projectImages/greenCobra1.png")}
            image2={require("../../../images/projectImages/greenCobra2.png")}
            job="Developer"
            startDate="Jun 2019"
            endDate="On going"
            description="
        I was given the opportunity by KTH to keep developing my bachelors degree project, GreenCobra. We built a web app that together with a scanner connected to a Raspberry Pi allows users to keep track of their foodstuff. The purpose of the system is to reduce food waste."
            link="https://greencobra.web.app/"
            codeLogos={[reactLogo, reduxLogo, firebaseLogo, pythonLogo]}
            color="#74AD4A"
          />
        </AppearingContainer>
        <AppearingContainer
          wrapperStyling={{ padding: "10px" }}
          parentStyling={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animationType="scale"
          transitionType="smooth"
          fading
        >
          <ProjectBrick
            cardId={"clabbes"}
            title="Clabbes Bygg"
            image={require("../../../images/projectImages/clabbes1.png")}
            image2={require("../../../images/projectImages/clabbes2.png")}
            job="Sole creator"
            startDate="April 2020"
            endDate="May 2020"
            description="A friend of mine expressed the need for an organizational platform at the construction company he works for. They needed a way to keep track of who has which tools. To solve this problem I created a web app in which each carpenter created an account, using these accounts they could then assign tools to each other and get an overview."
            link="https://clabbesbygg-20327.web.app/"
            codeLogos={[reactLogo, firebaseLogo]}
            color="#3A3A3A"
          />
        </AppearingContainer>
        <AppearingContainer
          wrapperStyling={{ padding: "10px" }}
          parentStyling={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animationType="scale"
          transitionType="smooth"
          fading
        >
          <ProjectBrick
            cardId="kollin"
            title="Kollin"
            image={require("../../../images/projectImages/kollinLaptop.png")}
            job="Front End developer"
            startDate="Oct 2019"
            endDate="Dec 2019"
            description="
          I was hired to help develop a new version of their website for an upcoming launch. We spent a lot of time planning best practice for code structure to ensure scalability while refactoring old code and adding new."
            link="https://kollin.io/"
            color="#6f8be2"
            codeLogos={[reactLogo, apolloLogo, graphQLLogo]}
          />
        </AppearingContainer>
        <AppearingContainer
          wrapperStyling={{ padding: "10px" }}
          parentStyling={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animationType="scale"
          transitionType="smooth"
          fading
        >
          <ProjectBrick
            cardId="clinTri"
            title="ClinTri"
            image={require("../../../images/projectImages/clinTriLaptop.png")}
            job="Developer, Designer"
            startDate="Dec 2019"
            endDate="Mar 2020"
            description={`
        During the course DH2321 Information Visualization at KTH me and 5 team members created a visualization of the clinicalTrials.gov database. We met up with a stakeholder from Karolinska Institutet and designed the visualization based on her requests.`}
            link="https://hugobergqvist.github.io/clintri/"
            color="#3b719f"
            codeLogos={[d3Logo, HTMLLogo, CSSLogo, JSLogo]}
          />
        </AppearingContainer>
      </div>
      <div className="projectContainer" id="otherProjects">
        <h1 className="projectContainerTitle">Other</h1>
        <AppearingContainer
          wrapperStyling={{ padding: "10px" }}
          parentStyling={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animationType="scale"
          transitionType="smooth"
          fading
        >
          <ProjectBrick
            cardId="kthMsc"
            title="University: KTH"
            image={require("../../../images/projectImages/Kth_Logo.png")}
            job="MSc. Interactive Media Technology"
            startDate="Aug 2016"
            endDate="Jun 2021"
            description="I am currently working on my master thesis, the last step of my education to become a civil engineer. During this education I've specialized in visual media, my Ms. thesis consists of integrating environmental and nutrition research into a digital inventory to support people in making more sustainable food purchases."
            link="https://www.kth.se/student/kurser/program/CMETE/20162/arskurs1?l=en"
            color="#2258A5"
          />
        </AppearingContainer>
        <AppearingContainer
          wrapperStyling={{ padding: "10px" }}
          parentStyling={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animationType="scale"
          transitionType="smooth"
          fading
        >
          <ProjectBrick
            cardId="kthBase"
            title="University: KTH"
            image={require("../../../images/projectImages/Kth_Campus.png")}
            job="Technical Preparatory Year"
            startDate="Aug 2015"
            endDate="Jun 2016"
            description="
        Since I did not take a science based program during high school, I had some mandatory courses to complete in order to get into the MSc programmes. This preparatory year let me complete those courses while getting into the KTH-way of studying."
            link="https://www.kth.se/utbildning/teknisk-basutbildning/tekniskt-basar-60-fup/utbildningsbeskrivning-1.705"
            color="#2258A5"
          />
        </AppearingContainer>
        <AppearingContainer
          wrapperStyling={{ padding: "10px" }}
          parentStyling={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animationType="scale"
          transitionType="smooth"
          fading
        >
          <ProjectBrick
            cardId="kjell"
            title="Kjell & Co"
            image={require("../../../images/projectImages/kjell.jpg")}
            job="Salesman"
            startDate="Nov 2012"
            endDate="Jun 2018"
            description="
        I worked both full-time and part-time as a salesman in several stores, advicing and instructing customers regarding electronics as well as selling the products."
            link="https://www.kjell.com/"
            color="#0073b1"
          />
        </AppearingContainer>
        <AppearingContainer
          wrapperStyling={{ padding: "10px" }}
          parentStyling={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animationType="scale"
          transitionType="smooth"
          fading
        >
          <ProjectBrick
            cardId="dollarStore"
            title="Dollarstore"
            job="Salesman"
            image={require("../../../images/projectImages/dollarStore.jpeg")}
            startDate="Jun 2012"
            endDate="Aug 2012"
            link="https://dollarstore.se/"
            description="I worked as a salesman in one of their stores during my first summer after high school."
            color="#E30813"
          />
        </AppearingContainer>
      </div>
    </div>
  );
};

export default Projects;
