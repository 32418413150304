import React from "react";

const BubbleDetails = ({
  info,
  setExpandBubble,
  setCurrentBubbleInfo,
  screenMode,
}) => {
  let titleFontSize = screenMode === "thin" ? "16pt" : "20pt";
  let imageSize = screenMode === "thin" ? "100px" : "150px";
  let skillRef = React.createRef();
  const handleClick = () => {
    setCurrentBubbleInfo({});
    setExpandBubble(false);
  };

  const drawSkill = () => {
    skillRef.current.style.left = `${info.skillLevel}%`;
  };
  return (
    <div id="bubbleDetails" onClick={() => handleClick()}>
      <div
        id="bubbleDetailImageWrapper"
        style={{
          width: imageSize,
          height: imageSize,
          minHeight: imageSize,
          minWidth: imageSize,
        }}
        className="constantShadow"
      >
        <img
          id="bubbleDetailImage"
          onLoad={() => drawSkill()}
          src={info.image}
          alt="bubbleImage"
        ></img>
      </div>
      <div id="bubbleDetailsInfo">
        <h3 id="bubbleDetailTitle" style={{ fontSize: titleFontSize }}>
          {info.title}
        </h3>
        <div id="bubbleDetailsSkillWrapper">
          <div id="bubbleDetailsMeter">
            <div
              id="bubbleDetailsSkill"
              ref={skillRef}
              style={{ width: `100%` }}
            ></div>
          </div>
          {info.skillLevel !== "0" && (
            <p id="bubbleDetailSkillPercentage">{`${info.skillLevel}% fluent`}</p>
          )}
        </div>
        <p id="bubbleDetailText"> {info.description}</p>
      </div>
    </div>
  );
};

export default BubbleDetails;
