import React from "react";
import "./containerStyle.css";
import Projects from "../presentational/Projects/Projects";

const Home = () => {
  return (
    <div id="homeBody">
      <Projects />
    </div>
  );
};

export default Home;
