import React, { useState } from "react";
import "./ImageLoader.css";

const ImageLoader = ({
  source,
  imageClass,
  imageId,
  imageAlt = "portfolio",
  divStyle,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  let img = new Image();
  img.src = source;
  img.onload = () => {
    setImageLoaded(true);
  };

  return imageLoaded ? (
    <img src={source} id={imageId} className={imageClass} alt={imageAlt} />
  ) : (
    <div className="imageLoader constantShadow" style={divStyle}>
      <div className="loaderWave" />
    </div>
  );
};

export default ImageLoader;
