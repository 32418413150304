import React, { useEffect, useState } from "react";
import GravityBubbleContainer from "../GravityBubbles/GravityBubbleContainer";
import "./SkillsContainer.css";

const bubbles = [
  {
    image: require("../../../images/codeLogos/reactJSLogo.svg"),
    title: "React JS",
    description:
      'I absolutely love react js, the magic of creating new projects with the single line "npx create-react-app" is hard to beat. This is without question the framework that I have used the most.',
    skillLevel: 95,
    width: "80px",
    expandHeight: "175px",
  },
  {
    image: require("../../../images/codeLogos/HTMLJSCSSLogo.png"),
    title: "HTML, CSS & JS",
    description:
      "It feels unnecessary to add these to the list as they're almost required to code for the web, but for the sake of clarity I'll mention that I am very familiar with them. I have yet to try typescript, but I am sure I will encounter it eventually.",
    skillLevel: 90,
    width: "140px",
    expandHeight: "175px",
  },
  {
    image: require("../../../images/codeLogos/pythonLogo.svg"),
    title: "Python",
    description:
      "Python is fantastic in it's simplistic yet powerful syntax. The first two years of my education I spent creating programs, games and algorithms with it.",
    skillLevel: 85,
    width: "80px",
    expandHeight: "150px",
  },
  {
    image: require("../../../images/codeLogos/ReduxLogo.svg"),
    title: "Redux",
    description:
      "Redux came in handy during the Green Cobra project where we really needed a global state manager, I didn't love all the boilerplate but once used to it didn't bother me either.",
    skillLevel: 76,
    width: "80px",
    expandHeight: "150px",
  },
  {
    image: require("../../../images/codeLogos/firebaseLogo.svg"),
    title: "Firebase",
    description:
      "I've used firebase for several projects, most of them in combination with React, and I really like it. I have used their real-time database and firestore the most, however their cloud functions have proven handy as well. ",
    skillLevel: 85,
    width: "80px",
    expandHeight: "175px",
  },
  {
    image: require("../../../images/codeLogos/ApolloLogo.png"),
    title: "Apollo Engine",
    description:
      "We used apollo to query the database at Kollin and I really got attached to the great built in functionalites such as lazy loading, polling, re-fetching etc.",
    skillLevel: 45,
    width: "120px",
    expandHeight: "130px",
  },
  {
    image: require("../../../images/codeLogos/SQLLogo.svg"),
    title: "SQL",
    description:
      "During the course DD1334 - Database Technology at KTH I got to work a lot with SQL and it resulted in an A. I found it pretty intuitive however I seem to have found my calling in the front end.",
    skillLevel: 44,
    width: "75px",
    expandHeight: "150px",
  },
  {
    image: require("../../../images/codeLogos/reactNativeLogo.svg"),
    title: "React Native",
    description:
      "I've developped a step counting app and a litter picking app in react native. Both for school projects and neither reaching the app store but they showed potential! So far I've only used it with JS, hoping to test it with typescript soon!",
    skillLevel: 70,
    width: "110px",
    expandHeight: "175px",
  },
  {
    image: require("../../../images/codeLogos/UnityLogo.svg"),
    title: "Unity",
    description:
      "I've encountered Unity in a couple of courses at KTH and I've used it in some minor personal projects, I'm a big fan! The combination of C# scripting and drag-and-drop through their GUI is addictive.",
    skillLevel: 50,
    width: "80px",
    expandHeight: "165px",
  },
  {
    image: require("../../../images/codeLogos/d3Logo.png"),
    title: "D3.js",
    description:
      "During the course DH2321 - Information Visualization at KTH I created a couple of projects using D3. You can read more about my main project, ClinTri, under Experiences.",
    skillLevel: 27,
    width: "80px",
    expandHeight: "150px",
  },
  {
    image: require("../../../images/codeLogos/StorybookLogo.png"),
    title: "Storybook",
    description:
      "During my time at Kollin we worked with Storybook to develop react components separate from the website, which worked very well! My experience with it is limited but I look forward to using it again.",
    skillLevel: 20,
    width: "85px",
    expandHeight: "160px",
  },
  {
    image: require("../../../images/codeLogos/graphQLLogo.svg"),
    title: "GraphQL",
    description:
      "I never got to experience setting up a graphQL database, however I got a feeling for how it works when querying it with apollo. If I were to further develop knowledge of databases I would start with properly investigating graphQL!",
    skillLevel: 15,
    width: "80px",
    expandHeight: "175px",
  },
  {
    image: require("../../../images/designLogos/AdobeIllustratorLogo.svg"),
    title: "Adobe Illustrator",
    description:
      "Creating vector graphics in Illustrator was something I really wanted to be able to do which led to it being my go to software for all projects. I've designed a lot of icons, banners and other elements using it.",
    skillLevel: 84,
    width: "140px",
    expandHeight: "160px",
  },
  {
    image: require("../../../images/designLogos/AdobeXDLogo.svg"),
    title: "Adobe Xd",
    description:
      "My go-to program when it comes to creating mockups, I find it very intuitive and it has yet to disapoint when I want to sketch my ideas.",
    skillLevel: 75,
    width: "80px",
    expandHeight: "135px",
  },
  {
    image: require("../../../images/designLogos/AdobePhotoshopLogo.png"),
    title: "Adobe Photoshop",
    description:
      "Photoshop comes in handy as soon as I want to edit real images. I've used it mainly for editing pictures for personal use,  based on its broad functionality I'd say I've only scraped the surface.",
    skillLevel: 45,
    width: "160px",
    expandHeight: "160px",
  },
  {
    image: require("../../../images/otherLogos/bergqvistLogo.png"),
    title: "My company",
    description:
      'To be able to work for KTH I got approved for "F-skattesedel" which allow me to work as a consultant, bill and pay taxes accordingly.',
    width: "140px",
    skillLevel: "0",
    expandHeight: "110px",
  },
  {
    image: require("../../../images/designLogos/asepriteLogo.svg"),
    title: "Aseprite",
    description:
      "This simplistic software for creating pixel art was absolutely perfect for FeedIt, a game I created for school in 2020. It was the first time I tried pixel art and luckily found it pretty intuitive!",
    width: "140px",
    skillLevel: 90,
    expandHeight: "110px",
  },
  {
    image: require("../../../images/designLogos/audacityLogo.svg"),
    title: "Audacity",
    description:
      "One of those softwares that are endlessly complex but not really that hard if you want to create specific effects! I've used it for voice overs and sound effects in multiple projects.",
    width: "140px",
    skillLevel: 20,
    expandHeight: "110px",
  },
  {
    image: require("../../../images/codeLogos/cSharpLogo.svg"),
    title: "C#",
    description:
      "I've mostly worked with C# in Unity and I really like it. It feels like a softcore version of C++ and my knowledge in javascript gets me far, although some fundamental differences (such as arrays and lists) can throw me for a loop.",
    width: "140px",
    skillLevel: 50,
    expandHeight: "110px",
  },
  {
    image: require("../../../images/designLogos/blenderLogo.svg"),
    title: "Blender",
    description:
      "My interest for 3D modelling grew from no where during the summer of 2020 and Blender was my go to software. Since then I've used it for a couple of projects in school as well as privately and I really like it. I've mostly done low poly graphics but I'm interested in all types!",
    width: "140px",
    skillLevel: 50,
    expandHeight: "110px",
  },
];

const SkillsContainer = () => {
  const [bubblesToShow, setBubblesToShow] = useState(bubbles);
  const [filter, setFilter] = useState("");

  const updateLists = () => {
    let tempBubbles = [];
    bubbles.forEach((elem) => {
      if (elem.title.toLowerCase().includes(filter)) {
        tempBubbles.push(elem);
      }
    });

    setBubblesToShow(tempBubbles);
  };

  useEffect(() => {
    if (filter !== "") {
      updateLists();
    } else {
      setBubblesToShow(bubbles);
    }
  }, [filter, bubbles]);

  return (
    <div id="skillContainer">
      <div id="searchWrapper">
        <div id="searchContainer">
          <p id="searchHelperText">Search for skills:</p>
          <input
            type="text"
            id="skillSearchField"
            placeholder="Ex. Unity"
            value={filter}
            onChange={(e) => setFilter(e.target.value.toLowerCase())}
          />
        </div>
      </div>

      <div id="appleWatchContainer">
        <GravityBubbleContainer bubbles={bubblesToShow} />
      </div>
    </div>
  );
};

export default SkillsContainer;
