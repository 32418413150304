import React from "react";
import "./NavBarCell.css";
const NavBarCell = ({ children, onSelect, useRef }) => {
  return (
    <div className="NavbarCell" onClick={() => onSelect()} ref={useRef}>
      {children}
    </div>
  );
};

export default NavBarCell;
