import React, { useState, useEffect } from "react";
import "./Navbar.css";
import NavBarCell from "./NavBarCell";
import { withRouter } from "react-router-dom";

let navRef = React.createRef();
let homeRef = React.createRef();
let aboutRef = React.createRef();
let skillRef = React.createRef();
let underlineRef = React.createRef();
let placeFillerRef = React.createRef();

const Navbar = ({ history, location }) => {
  const [underlineMargin, setUnderlineMargin] = useState(0);
  const [underlineWidth, setUnderlineWidth] = useState(0);

  const handleNavbarUnderline = () => {
    let boundingRect = null;
    if (location.pathname === "/") {
      boundingRect = homeRef.current.getBoundingClientRect();
    } else if (location.pathname === "/about") {
      boundingRect = aboutRef.current.getBoundingClientRect();
    } else {
      boundingRect = skillRef.current.getBoundingClientRect();
    }
    setUnderlineMargin(boundingRect.x);
    setUnderlineWidth(boundingRect.width);
  };

  useEffect(() => {
    handleNavbarUnderline();
    window.addEventListener("resize", handleNavbarUnderline);
    return () => {
      window.removeEventListener("resize", handleNavbarUnderline);
    };
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    var header = document.getElementById("header");
    let sticky = header.offsetHeight;
    if (window.pageYOffset > sticky) {
      navRef.current.classList.add("sticky");
      placeFillerRef.current.style.display = "block";
    } else {
      navRef.current.classList.remove("sticky");
      placeFillerRef.current.style.display = "none";
    }
  };

  const onSelect = option => {
    history.replace(option);
  };

  return (
    <React.Fragment>
      <div id="navbar" ref={navRef}>
        <div id="navigationOptions">
          <NavBarCell onSelect={() => onSelect("/")} useRef={homeRef}>
            <p className="navOption">Experiences</p>
          </NavBarCell>
          <NavBarCell onSelect={() => onSelect("/skills")} useRef={skillRef}>
            <p className="navOption">Skills</p>
          </NavBarCell>
          <NavBarCell onSelect={() => onSelect("/about")} useRef={aboutRef}>
            <p className="navOption">About Me</p>
          </NavBarCell>
        </div>
        <div
          id="underline"
          style={{ marginLeft: underlineMargin, width: underlineWidth }}
          ref={underlineRef}
        />
      </div>
      <span
        ref={placeFillerRef}
        id="placeFiller"
        style={{ display: "none", height: "34px", width: "100vw" }}
      ></span>
    </React.Fragment>
  );
};

export default withRouter(Navbar);
