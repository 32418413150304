import React, { useState } from "react";
import ImageLoader from "../ImageLoader/ImageLoader";

const Bubble = ({
  children,
  bubbleNumber,
  setHoverBubble,
  title,
  image,
  description,
  skillLevel,
  setExpandBubble,
  setCurrentBubbleInfo,
}) => {
  const [hovering, setHovering] = useState(false);
  let bubbleRef = React.createRef();

  const onHover = () => {
    setHovering(true);
    setHoverBubble(bubbleNumber);
    bubbleRef.current.style.top = `-${skillLevel}%`;
  };

  const onExit = () => {
    setHovering(false);
    setHoverBubble(false);
    bubbleRef.current.style.top = `0px`;
  };

  const handleClick = () => {
    setCurrentBubbleInfo({
      title,
      skillLevel,
      image,
      description,
    });

    setExpandBubble(true);
  };

  return (
    <span
      onMouseEnter={() => onHover()}
      onMouseLeave={() => onExit()}
      onClick={() => handleClick()}
    >
      <div className="bubble" id={`bubble-${bubbleNumber}`}>
        <div className="bubbleCover" ref={bubbleRef} />
        <div className="bubbleImageWrapper">
          <ImageLoader
            source={image}
            imageClass="bubbleImage"
            imageAlt={`${title}`}
            divStyle={{ borderRadius: "50%" }}
          />
        </div>
        {children}
        <div
          className="skillLevel"
          style={
            hovering && skillLevel !== "0" ? { opacity: 0.9 } : { opacity: 0 }
          }
        >
          <p className="skillLevelText">{`${skillLevel}% fluent`}</p>
        </div>
      </div>
    </span>
  );
};

export default Bubble;
