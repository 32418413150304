import React from "react";
import "./containerStyle.css";
import SkillsContainer from "../presentational/SkillsContainer/SkillsContainer";
const Skills = () => {
  return (
    <div>
      <SkillsContainer />
    </div>
  );
};

export default Skills;
